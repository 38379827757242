<template>
	<div class="groupboutons">
		<button id="deprt" class="grand aide" @click="pageActive = 'Global'" :class="{active: pageActive == 'Global'}">Généralités</button>
		<button class="grand aide" @click="pageActive = 'France'" :class="{active: pageActive == 'France'}">France</button>
		<button class="grand aide" @click="pageActive = 'Autres'" :class="{active: pageActive == 'Autres'}">Autres pays</button>
		<button class="grand aide" @click="pageActive = 'Geocod'" :class="{active: pageActive == 'Geocod'}">Géocodage</button>
	</div>
	<div class="infos">
		<div class="expl">
			<component :is="pageActive" />
		</div>
	</div>
	<div style="width: 100%; font-size: 14px; color: #554931; margin-bottom: 10px; text-align: center">©2017-2025, communes.xyz</div>
</template>

<script>
  import Global from '@/components/infos/Global.vue'
  import France from '@/components/infos/France.vue'
  import Autres from '@/components/infos/Autres.vue'
  import Geocod from '@/components/infos/Geocod.vue'

	export default {
		name: 'InfosPays',
		components: { Global, France, Autres, Geocod },
		data() {
			return {
				pageActive: Global
			}
		},
		mounted() {
			byId('deprt').click()
		}
	}
</script>
